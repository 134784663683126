/*jshint esversion: 6 */
import {Link,} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import HumanErrorGet from "./HumanErrorGet";
import HumanPreloader from "./HumanPreloader";
import {url_api_skill_programming} from "../ApiUrls";

function HumanCreateSkillProgramming(props){
    "use strict";

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState([]);

    useEffect( () => {
        axios({
            method: "GET",
            url: url_api_skill_programming,
            responseType: 'json'
        })
        .then(response => {
            setResult(response.data);
            setIsLoaded(true);
        })
        .catch(function (error) {
            setIsLoaded(true);
            setError(error);
        });
    }, [props.update]);

    function ViewLabel(){
        return (
            <div
                className="profile__text-block d-flex justify-content-between align-items-baseline">
                <p className="profile__text">Skill Programming </p>
                <img className="profile__error d-none" src="/static/img/error.svg" alt="" />
            </div>
        )
    };

    function stars(star) {
        if (star === 0){
            star = 1;
        }
        if (star > 3){
            star = 3;
        }
        let result = [];
        for (let i=0; i<3; i++){
            if (i < star){
                result.push(<span>&#9733;</span>);
            }else{
                result.push(<span>&#9734;</span>);
            };
        }


        return (
            <div>
                {result}
            </div>
        )
    }

    if (error) {
        return ( <div> {ViewLabel()} <HumanErrorGet error={error} /> </div> );
    } else if (!isLoaded) {
        return( <div> {ViewLabel()} <HumanPreloader /> </div> );
    } else {
        return (
            <div>
                {/*<!----------------------------Language Programming---------------------------->*/}
                {ViewLabel()}
                <p className="profile__text profile__text_small d-md-none d-block">Nothing
                    selected yet</p>
                <a className="profile__modal-button button button_white justify-content-center align-items-center w-100 d-md-none d-flex"
                   id="edit_skills_button">Edit my skills set</a>
                <div className="profile__special-wrap" id="skills_wrap">
                    <div className="profile__form-full d-md-block d-none"
                         id="skills_full">
                        <div className="profile__form-check d-flex"><input
                            name="language" id="language" type="text"
                            className="profile__input profile__input_plus"
                            placeholder="" form="profile" />
                            <a className="profile__plus d-flex justify-content-center align-items-center"
                               data-toggle="modal" data-target="#Modal_skill">
                                <img src="/static/img/circle-plus.svg" alt="" />
                            </a>
                        </div>
                        <div className="profile__check p-0">
                            {result.map( t  =>
                                <div className="profile__checkbox">
                                    <input className="profile__custom-checkbox"
                                           type="checkbox" id={`skill${t.pk}`} name={`skill${t.pk}`}
                                           form="profile" />
                                        <label htmlFor={`skill${t.pk}`}>{t.title} {stars(t.level)}</label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default HumanCreateSkillProgramming;
