/*jshint esversion: 6 */
import {Link,} from "react-router-dom";
import React from "react";
// import gs from "../../Singleton"

function HumanErrorGet(props){
	'use strict';

    function refreshPage(){
        window.location.reload();
    }

	return (
            <div>
			{/*<!----------------------------Error---------------------------->*/}
                <br /><br />
                <Link to={{pathname: `/`, fromDashboard: false}}>{"<<== Return to Main Page."}</Link>
                <br /><br />
                Error: {props.error.message}
                <br /><br />
                Fields:
                <br /><br />
                {props.error.response.data ? Object.entries(props.error.response.data).map( ([key, value], num)  =>
                    <p>{num}: {key} - {value}</p>
                ) : props.error}
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={ refreshPage }> <span>Reload</span> </button>
                </div>
            </div>
	);
}

export default HumanErrorGet;
