/*jshint esversion: 6 */
import React, { useState } from 'react';
import axios from "axios";
import gs from "../../Singleton";
import {url_api_language_programming} from "../ApiUrls";


function HumanCreateLanguageProgrammingModal(props){
    "use strict";

    const [newLanguageProgramming, setNewLanguageProgramming] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [newGoodAdd, setNewGoodAdd] = useState(false);
    const [newError, setNewError] = useState(null);

    function createNewElementGood(response){
        if (response.status === 201) {
            setNewGoodAdd(true);
            props.update('language_programming');
        } else{
            setNewGoodAdd(false);
        }
    }

    const submitAddNewLanguageProgramming = e => {
        setNewGoodAdd(false);
        axios({
            method: "POST",
            url: url_api_language_programming,
            responseType: 'json',
            contentType: 'application/json',
            data: {
                title: newLanguageProgramming,
                description: newDescription,
            }
            })
            .then(response => {
                setNewError(null);
                setNewLanguageProgramming("");
                setNewDescription("");
                createNewElementGood(response);
            })
            .catch(function (error) {
                setNewError(error);
            });
    };

    function clear_messages(){
        setNewError(null);
        setNewGoodAdd(false);
    }

    function ModalHeader(){
        return (
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                onClick={clear_messages}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        )
    };

    function ModalFooter(){
        return (
            <div>
                <div className="modal-footer">
                    <input type="button" className="button button_blue" form="lang_add"
                           value="Add" onClick={submitAddNewLanguageProgramming} />
                    <button type="button" className="button button_white" data-dismiss="modal"
                    onClick={clear_messages} >Close
                    </button>
                </div>
                {newError
                    ? newError.response.data.error ? newError.response.data.error : ""
                : ""}
                {newGoodAdd?"Element Add!!":""}
            </div>
        )
    };

    return (
        <div className="modal fade" id="Modal_language" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1"
             aria-hidden="true">
            {/*<!-----------------------------------Modal Language Programming-------------------->*/}
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {ModalHeader()}
                    <div className="modal-body">
                        <h2 className="modal-title" id="myModalLabel1">Add new language programming</h2>
                        <form id="lang_add">
                            <input className="profile__input" type="text" placeholder="Input language" name="name"
                                   form="lang_add"
                                   value={newLanguageProgramming?newLanguageProgramming:''}
                                   onChange={t => setNewLanguageProgramming(t.target.value)} />
                        </form>
                    </div>
                    {ModalFooter()}
                </div>
            </div>
        </div>
    )
}

export default HumanCreateLanguageProgrammingModal;
