/*jshint esversion: 6 */
import React, { useState } from 'react';
import axios from "axios";
import gs from "../../Singleton";
import {url_api_gender} from "../ApiUrls";


function HumanCreateIntervalWorkModal(props){
    "use strict";

    const [newGender, setNewGender] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [newGoodAdd, setNewGoodAdd] = useState(false);
    const [newError, setNewError] = useState(null);

    function createNewElementGood(response){
        if (response.status === 201) {
            setNewGoodAdd(true);
            props.update('gender');
        } else{
            setNewGoodAdd(false);
        }
    }

    const submitAddNewGender = e => {
        setNewGoodAdd(false);
        axios({
            method: "POST",
            url: url_api_gender,
            responseType: 'json',
            contentType: 'application/json',
            data: {
                title: newGender,
                description: newDescription,
            }
            })
            .then(response => {
                setNewError(null);
                setNewGender("");
                setNewDescription("");
                createNewElementGood(response);
            })
            .catch(function (error) {
                setNewError(error);
            });
    };

    function clear_messages(){
        setNewError(null);
        setNewGoodAdd(false);
    }

    return (
            <div className="modal fade" id="Modal_work" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel5"
                 aria-hidden="true">
                {/*<!-----------------------------------Modal Interval Work-------------------->*/}
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2 className="modal-title" id="myModalLabel5">Add new interval work</h2>
                            <form id="work_add">
                                <input className="profile__input" type="text" placeholder="Input interwal work"
                                       name="work" form="work_add" />
                                    <p className="profile__text">From</p>
                                    <input className="profile__input" type="time" name="from" form="work_add" />
                                        <p className="profile__text">To</p>
                                        <input className="profile__input" type="time" name="to" form="work_add" />
                            </form>
                        </div>
                        <div className="modal-footer">
                            <input type="button" className="button button_blue" form="work_add"
                                   value="Save changes"></input>
                            <button type="button" className="button button_white" data-dismiss="modal">Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default HumanCreateIntervalWorkModal;
