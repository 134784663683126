/*jshint esversion: 6 */
import {Link,} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import useWindowSize from "../hooks/getWindowSize";

function HumanListElementsWithStars(props){
    const [data, setData] = useState({});
    const [view, setView] = useState(false);
    const [viewMobile, setViewMobile] = useState(false);


    const size = useWindowSize();
    // const nullOrValue = (key, val) => {
    //     if (val === null){
    //         return "null";
    //     } else{
    //         if (key === "hours"){
    //             if (val.timezone.hours < 0){
    //                 return "GMT -"  + val.timezone.hours;
    //             } else {
    //                 return "GMT +" + val.timezone.hours;
    //             }
    //         }
    //         if ( (key === "specialist_level")
    //         || (key === "status_active")
    //         || (key === "frame_lang")
    //         || (key === "framework_language") ){
    //             return val.title;
    //         }
    //     }
    // }


    // // const stars = star => {
    function stars(star) {
        if ( (star === 1) || (star === 0) ){
            return [
                <i className="fas fa-star"/>,
                <i className="far fa-star"/>,
                <i className="far fa-star"/> ];
        }
        if (star === 2){
            return [
                <i className="fas fa-star"/>,
                <i className="fas fa-star"/>,
                <i className="far fa-star"/> ];
        }
        if (star === 3){
            return [
                <i className="fas fa-star"/>,
                <i className="fas fa-star"/>,
                <i className="fas fa-star"/> ];
        }
    }

    return (
        <div className={props.name+((viewMobile === false)?(" d-flex"):(" d-block"))}>
            <Link className="button button_blue main__button d-block d-xl-none" id={props.id+"_button_1"}
                  onClick={() => {
                    if (viewMobile === false) {
                        setViewMobile(true);
                    } else {
                        setViewMobile(false);
                    };
                }}
            >
                Open
            </Link>
            <div className={"main__text-wrap d-xl-flex "+((viewMobile === false)?("d-none"):("d-flex"))} id={props.id+"_list_1"}>
                {props.data.map((p,i) =>
                    ( (i < 3) || (view === true) ) ? (
                        <div
                            className="main__text main__text_white d-flex flex-column align-items-center">
                            <div
                                className="main_s d-flex justify-content-center align-items-center">
                                {stars(p.level)}
                            </div>
                            <p className="main__text-big">{p.title}</p>
                            <p className="main__text-small">{p.language?p.language.title:""}</p>
                        </div>
                    ): ("")
                )}
                {props.data.length > 3 ? (
                    <p className="main__text main__text_gray"
                    onClick={() => {
                        if (view === false) {
                            setView(true);
                        } else {
                            setView(false);
                        };
                    }}
                    >{view === false ? "All "+props.data.length : "Collapse" }</p>
                ):(<span></span>) }
            </div>
        </div>
    )
}

export default HumanListElementsWithStars;


    //             <div>
    //   {size.width}px / {size.height}px
    // </div>
