/*jshint esversion: 6 */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import HumanErrorGet from "./HumanErrorGet";
import HumanPreloader from "./HumanPreloader";
import {url_api_level_language} from "../ApiUrls";

function HumanCreateLevelEnglish(props){
    "use strict";

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState([]);

    useEffect( () => {
        axios({
            method: "GET",
            url: url_api_level_language,
            responseType: 'json'
        })
        .then(response => {
            setResult(response.data.results);
            setIsLoaded(true);
        })
        .catch(function (error) {
            setIsLoaded(true);
            setError(error);
        });
    }, [props.update]);
    
    function ViewLabel(){
        return (
            <div
                className="profile__text-block d-flex justify-content-between align-items-baseline">
                <p className="profile__text">Level Language</p>
                <img src="/static/img/error.svg" className="profile__error d-none" alt="" />
            </div>
        )
    };

    if (error) {
        return ( <div> {ViewLabel()} <HumanErrorGet error={error} /> </div> );
    } else if (!isLoaded) {
        return( <div> {ViewLabel()} <HumanPreloader /> </div> );
    } else {
        return (
            <div>
                {ViewLabel()}
                <select name="language" id="language" className="profile__select"
                        form="profile"
                        onChange={(value) => {
                            props.selectLevelEnglish(value.target.value);
                        }}>

                    <option key="0" value="0">Select Level</option>
                    {result.map( t  =>
                        <option key={t.pk} value={t.pk}>{t.CEFR} -
                            {t.CERF=="CERF"?"CERF - ":""}{t.title}
                        </option>
                    )}
                </select>
            </div>
        )
    }
}

export default HumanCreateLevelEnglish;
