/*jshint esversion: 6 */
import {Link,} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import gs from "../Singleton"
import axios from "axios";
// import HumanFilter from "./template/HumanFilter"
import HumanFilterSelect from "./template/HumanFilterSelect";
import HumanPreloader from "./template/HumanPreloader";
import useScript from './hooks/useScript';
import HumanListElementsWithStars from "./template/HumanListElementsWithStars";
import {url_api_human} from "./ApiUrls";


function HumanList(){
    const [humans_count, setHumans_count]=useState([]);

    const [result, setResults] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState(false);
    const host = gs.getHost();


    useEffect( () => {
        axios({
            method: "GET",
            url: url_api_human,
            responseType: 'json'
        })
        .then(response => {
            setResults(response.data.results);
            setHumans_count(response.data.count);
            setIsLoaded(true);
            console.log("1 response ->", response);
            console.log("1 result ->", result);
            console.log("1 humans_count ->", humans_count);
            //wds document.body.appendChild(script);
        })
        .catch(function (error) {
            console.log(error);
            setError(error);
            setIsLoaded(true);
        });
        setAlert(false);
    }, [alert, host]) // , result, humans_count])
    // console.log("ROOT->", result);

    const nullOrValue = (key, val) => {
        if (val === null){
            return "null";
        } else{
            if (key === "hours"){
                if (val.timezone.hours < 0){
                    return "GMT -"  + val.timezone.hours;
                } else {
                    return "GMT +" + val.timezone.hours;
                }
            }
            if ( (key === "specialist_level")
            || (key === "status_active")
            || (key === "frame_lang")
            || (key === "framework_language") ){
                return val.title;
            }
        }
    }


    // const stars = star => {
    function stars(star) {
        if ( (star === 1) || (star === 0) ){
            return [
                <i className="fas fa-star"/>,
                <i className="far fa-star"/>,
                <i className="far fa-star"/> ];
        }
        if (star === 2){
            return [
                <i className="fas fa-star"/>,
                <i className="fas fa-star"/>,
                <i className="far fa-star"/> ];
        }
        if (star === 3){
            return [
                <i className="fas fa-star"/>,
                <i className="fas fa-star"/>,
                <i className="fas fa-star"/> ];
        }
    }

    function refreshPage(){
        window.location.reload();
    }
    if (error) {
        return (
            <div>
                <br /><br />
                Error: {error.message}
                <br /><br />
                Fields:
                <br /><br />
                {error.response?Object.entries(error.response.data).map( ([key, value], num)  =>
                    <p>{num}: {key} - {value}</p>
                ):""}
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={ refreshPage }> <span>Reload</span> </button>
                </div>
            </div>
        )
    } else if (!isLoaded) {
        return(
            <HumanPreloader />
    )
    } else {
          return (
          	<div>
				{/*<HumanFilter />*/}
				{/*<!-------------------------------Main-------------------->*/}
				<section className="main" id="main">
                  	<div className="container-fluid">
                      <div className="row">
                          <HumanFilterSelect />
                          <div className="col-12 main__bottom p-0">
							<div className="col-12 main__header d-flex m-auto p-0">
								  <div className="col-11 main__header-line d-flex m-auto p-0">
									  <p className="main__number">#</p>
									  <p className="main__first">Active</p>
									  <p className="main__second">Time</p>
									  <p className="main__third">Name</p>
									  <p className="main__fourth">Level</p>
									  <p className="main__fifth">Languages</p>
									  <p className="main__six">Frameworks</p>
									  <p className="main__seven">Skills</p>
								  </div>
							  </div>
                            <div className = "col-12 main__body p-0">
                                {result.map(h =>
                                    <div className="main__body-line col-11 m-auto d-flex">

                                        <Link className="main__number main__number_bg"
                                        to={{
                                            pathname: `/human/${h.pk}/`,
                                            fromDashboard: false
                                        }}><p>{h.pk}</p></Link>

                                        <p className="main__first">{h.status_active}</p>
                                        <p className="main__second">{nullOrValue("hours", h.city)}</p>

                                        <Link className="main__third"
                                        to={{
                                            pathname: `/human/${h.pk}/`,
                                            fromDashboard: false
                                        }}>{h.name}</Link>

                                        <div className="main__fourth d-flex">
                                            <p className="">{h.specialist_level}</p>
                                        </div>
                                        <HumanListElementsWithStars name="main__fifth" id="lang" data={h.language_programming}/>
                                        <HumanListElementsWithStars name="main__six" id="fw" data={h.framework_programming}/>
                                        <HumanListElementsWithStars name="main__seven" id="skills" data={h.skills_programming}/>
                                    </div>
                                    )}
                            </div>
                          </div>
                      </div>
			  		</div>
				</section>
			</div>
          )
      }
}

export default HumanList;
