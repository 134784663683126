/*jshint esversion: 6 */
import React, { useState } from 'react';
import axios from "axios";
import gs from "../../Singleton";
import {url_api_gender} from "../ApiUrls";


function HumanCreateRateWorkModal(props){
    "use strict";

    const [newGender, setNewGender] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [newGoodAdd, setNewGoodAdd] = useState(false);
    const [newError, setNewError] = useState(null);

    function createNewElementGood(response){
        if (response.status === 201) {
            setNewGoodAdd(true);
            props.update('gender');
        } else{
            setNewGoodAdd(false);
        }
    }

    const submitAddNewGender = e => {
        setNewGoodAdd(false);
        axios({
            method: "POST",
            url: url_api_gender,
            responseType: 'json',
            contentType: 'application/json',
            data: {
                title: newGender,
                description: newDescription,
            }
            })
            .then(response => {
                setNewError(null);
                setNewGender("");
                setNewDescription("");
                createNewElementGood(response);
            })
            .catch(function (error) {
                setNewError(error);
            });
    };

    function clear_messages(){
        setNewError(null);
        setNewGoodAdd(false);
    }

    return (
        <div className="modal fade" id="Modal_rate" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel4"
             aria-hidden="true">
        {/*<!-----------------------------------Modal Rate Work-------------------->*/}
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h2 className="modal-title" id="myModalLabel4">Add new rate</h2>
                        <form id="rate_add">
                            <input className="profile__input" type="text" placeholder="Input rate" name="rate"
                                   form="rate_add" />
                                <p className="profile__text">Language programming</p>
                                <select className="profile__select profile__select_plus w-100" form="rate_add">
                                    <option value="skill1">C++</option>
                                    <option value="skill2">Python</option>
                                    <option value="skill3">C</option>
                                </select>
                                <p className="profile__text">Framework</p>
                                <select className="profile__select profile__select_plus w-100" form="rate_add">
                                    <option value="skill1">Django</option>
                                    <option value="skill2">Django</option>
                                    <option value="skill3">Django</option>
                                </select>
                                <p className="profile__text">$</p>
                                <input className="profile__input" type="number" value="0" name="price"
                                       form="rate_add" />
                        </form>
                    </div>
                    <div className="modal-footer">
                        <input type="button" className="button button_blue" form="rate_add"
                               value="Save changes"></input>
                        <button type="button" className="button button_white" data-dismiss="modal">Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HumanCreateRateWorkModal;
