/*jshint esversion: 6 */
import {Link,} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import HumanErrorGet from "./HumanErrorGet";
import HumanPreloader from "./HumanPreloader";
import {url_api_gender} from "../ApiUrls";

function HumanCreateGender(props){
    "use strict";

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState([]);

    useEffect( () => {
        axios({
            method: "GET",
            url: url_api_gender,
            responseType: 'json'
        })
        .then(response => {
            setResult(response.data.results);
            setIsLoaded(true);
        })
        .catch(function (error) {
            setIsLoaded(true);
            setError(error);
        });
    }, [props.update]);

    function ViewLabel(){
        return (
            <div
                className="profile__text-block d-flex justify-content-between align-items-baseline">
                <p className="profile__text">Gender</p>
                <img src="/static/img/error.svg" className="profile__error d-none"
                     alt="" />
            </div>
        )
    };

    if (error) {
        return ( <div> {ViewLabel()} <HumanErrorGet error={error} /> </div> );
    } else if (!isLoaded) {
        return( <div> {ViewLabel()} <HumanPreloader /> </div> );
    } else {
        return (
            <div>
                {ViewLabel()}
                <div className="profile__form-check d-flex">
                    {/*<!----------------------------Gender---------------------------->*/}
                    <select name="gender" id="gender"
                            className="profile__select profile__select_plus"
                            form="profile"
                            onChange={(value) => {
                                props.selectGender(value.target.value);
                            }}>
                        <option key="0" value="0">Select Gender</option>
                        {result.map(t =>
                            <option key={t.pk} value={t.pk}>{t.title} - {t.description}</option>
                        )}
                    </select>
                    <a className="profile__plus d-flex justify-content-center align-items-center"
                       data-toggle="modal" data-target="#Modal_gender">
                        <img src="/static/img/circle-plus.svg" alt=""/>
                    </a>
                </div>
            </div>
        )
    }
};

export default HumanCreateGender;
