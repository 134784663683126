/*jshint esversion: 6 */
// import {Link,} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
// import ReactDOM from 'react-dom';
// import TimeInput from 'react-input-time';
// import axios from 'axios';
// import gs from "../Singleton"
// import DialogInputHumanCreate from "./dialog_windows/DialogInputHumanCreate";
// import {TextField} from "@material-ui/core";
// import {Link} from "react-router-dom";
// import HumanMenu from "./template/HumanMenu"
// import HumanFilter from "./template/HumanFilter";
import HumanPreloader from "./template/HumanPreloader";
import HumanErrorGet from "./template/HumanErrorGet";
import HumanCreateGender from "./template/HumanCreateGender";
import HumanCreateLevelEnglish from "./template/HumanCreateLevelEnglish";
import HumanCreateCity from "./template/HumanCreateCity";
import HumanCreateLanguageProgramming from "./template/HumanCreateLanguageProgramming";
import HumanCreateSkillProgramming from "./template/HumanCreateSkillProgramming";

import HumanCreateGenderModal from "./template/HumanCreateGenderModal";
import HumanCreateLanguageProgrammingModal from "./template/HumanCreateLanguageProgrammingModal";
import HumanCreateSkillProgrammingModal from "./template/HumanCreateSkillProgrammingModal";
import HumanCreateFrameworkProgrammingModal from "./template/HumanCreateFrameworkProgrammingModal";
import HumanCreateIntervalWorkModal from "./template/HumanCreateIntervalWorkModal";
import HumanCreateRateWorkModal from "./template/HumanCreateRateWorkModal";

// const gs = new GlobalState(0);

function HumanCreate(effect, deps){
    // const [isLoaded, setIsLoaded] = useState(false);
    // const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const [error, setError] = useState(false);

    const [surname, setSurname] = useState('');
    const [name, setName] = useState('');
    const [middle_name, setMiddle_name] = useState('');
    const [nickname, setNickname] = useState('');


    function ErrorMessage(props) {
        const badText = e => {
            return (
                <div>
                    <h6 style={{color: "red"}}>
                        {props.title} - {e}
                    </h6>
                </div>
            )
        }

        return (
            <div>
                {props.error
                ?props.error.response.data[props.title]
                    ? badText(props.error.response.data[props.title]):"":""}
            </div>
        );
    }

    const [gender, setGender] = useState(null);
    const [levelEnglish, setLevelEnglish] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [city, setCity] = useState(null);

    const [updateGender, setUpdateGender] = useState(false);
    const [updateLanguageProgramming, setUpdateLanguageProgramming] = useState(false);
    const [updateSkillProgramming, setUpdateSkillProgramming] = useState(false);
    const updateChildComponent = (childComponent) => {
        if (childComponent === "gender"){
            setUpdateGender(!updateGender);
        }
        if (childComponent === "language_programming"){
            setUpdateLanguageProgramming(!updateLanguageProgramming);
        }
        if (childComponent === "skill_programming"){
            setUpdateSkillProgramming(!updateSkillProgramming);
        }
    }


    if (error) {
        return ( <HumanErrorGet error={error} /> );
    } else if (!isLoaded) {
        return( <HumanPreloader /> );
    } else {
        return (
            <div>
                {/*<!-------------------------------Profile-------------------->*/}
                <section className="profile" id="profile">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-11 col-12 profile__modal p-0">
                                <div className="profile__header col-12">
                                    <div
                                        className="profile__header-text col-md-9 col-11 m-auto d-flex justify-content-between">
                                        <h1 className="profile__title">
                                            New Profile
                                        </h1>
                                        <Link to="/" className="profile__button d-none d-lg-block">Close page</Link>
                                    </div>
                                </div>
                                <div className="profile__body col-12">
                                    <form id="profile" action=""></form>
                                    <div className="profile__body-content col-md-9 col-12 m-auto">
                                        <div
                                            className="profile__head-block d-flex justify-content-between align-items-baseline">
                                            <h2 className="profile__subtitle">
                                                Personal Data
                                            </h2>
                                            <p className="profile__text-red">
                                                Please check this forms
                                            </p>
                                        </div>
                                        <div className="profile__form d-flex p-0 justify-content-between">
                                            <div className="profile__form-left col-12 col-md-5 p-0">
                                                <div
                                                    className="profile__text-block d-flex justify-content-between align-items-baseline">
                                                    <p className="profile__text">Name</p>
                                                    <img className="profile__error" src="/static/img/error.svg" alt="" />
                                                </div>
                                                <input className="profile__input profile__input_red" type="text"
                                                       placeholder="Input name" name="name" form="profile"
                                                       value={name} onChange={t => setName(t.target.value)} />
                                                <div
                                                    className="profile__text-block d-flex justify-content-between align-items-baseline">
                                                    <p className="profile__text">Surname</p>
                                                    <img className="profile__error" src="/static/img/error.svg" alt=""/>
                                                </div>
                                                <input className="profile__input profile__input_red" type="text"
                                                       placeholder="Input surname" name="surname" form="profile"
                                                       value={surname} onChange={t => setSurname(t.target.value)} />
                                                <div
                                                    className="profile__text-block d-flex justify-content-between align-items-baseline">
                                                    <p className="profile__text">Middlename</p>
                                                    <img src="/static/img/error.svg" className="profile__error d-none"
                                                         alt="" />
                                                </div>
                                                <input className="profile__input" type="text"
                                                       placeholder="Input middlename" name="middlename" form="profile"
                                                       value={middle_name} onChange={t => setMiddle_name(t.target.value)} />
                                            </div>
                                            <div className="profile__form-right col-12 col-md-5 p-0">
                                                <div className="">
                                                    <div
                                                        className="profile__text-block d-flex justify-content-between align-items-baseline">
                                                        <p className="profile__text">Nickname</p>
                                                        <img src="/static/img/error.svg" className="profile__error d-none"
                                                             alt="" />
                                                    </div>
                                                    <input className="profile__input" type="text"
                                                           placeholder="Input nickname" name="nickname" form="profile"
                                                           value={nickname} onChange={t => setNickname(t.target.value)} />
                                                    <HumanCreateGender update={updateGender} selectGender={(value) => {
                                                        setGender(value);
                                                    }}/>
                                                    <HumanCreateLevelEnglish selectLevelEnglish={(value) => {
                                                        setLevelEnglish(value);
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile__body-content col-md-9 col-12 m-auto">
                                        <div
                                            className="profile__head-block d-flex justify-content-between align-items-baseline">
                                            <h2 className="profile__subtitle">
                                                Contact Info
                                            </h2>
                                            <p className="profile__text-red d-none">
                                                Please check this forms
                                            </p>
                                        </div>
                                        <div className="profile__form d-flex p-0 justify-content-between">
                                            <div className="profile__form-left col-12 col-md-5 p-0">
                                                <div
                                                    className="profile__text-block d-flex justify-content-between align-items-baseline">
                                                    <p className="profile__text">Phone</p>
                                                    <img className="profile__error d-none" src="/static/img/error.svg" alt="" />
                                                </div>
                                                <input className="profile__input" type="tel"
                                                       placeholder="+7 981 433 .. .." name="phone" form="profile"
                                                       value={phone} onChange={t => setPhone(t.target.value)} />
                                                <div
                                                    className="profile__text-block d-flex justify-content-between align-items-baseline">
                                                    <p className="profile__text">E-mail</p>
                                                    <img className="profile__error d-none" src="/static/img/error.svg"
                                                         alt="" />
                                                </div>
                                                <input className="profile__input" type="email"
                                                       placeholder="example@mail.com" name="email" form="profile"
                                                       onChange={t => setEmail(t.target.value)} />
                                            </div>
                                            <div className="profile__form-right col-12 col-md-5 p-0">
                                                <HumanCreateCity selectCity={(value) => {
                                                        setCity(value);
                                                    }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile__body-content col-md-9 col-12 m-auto">
                                        <div
                                            className="profile__head-block d-flex justify-content-between align-items-baseline">
                                            <h2 className="profile__subtitle">
                                                Hard Skills
                                            </h2>
                                            <p className="profile__text-red d-none">
                                                Please check this forms
                                            </p>
                                        </div>
                                        <p className="profile__text profile__text_small profile__text_pb d-md-none d-block">In
                                            this section, you need to select the skills that you are good at and plan to
                                            use in working with us.</p>
                                        <div className="profile__form d-flex p-0 justify-content-between">
                                            <div className="profile__form-left col-12 col-md-5 p-0">
                                                <HumanCreateLanguageProgramming update={updateLanguageProgramming} />
                                                <HumanCreateSkillProgramming update={updateSkillProgramming} />
                                            </div>
                                            <div className="profile__form-right col-12 col-md-5 p-0">
                                            </div>

                                        </div>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <HumanCreateGenderModal update={updateChildComponent} />
                <HumanCreateLanguageProgrammingModal update={updateChildComponent} />
                <HumanCreateSkillProgrammingModal update={updateChildComponent} />
                <HumanCreateFrameworkProgrammingModal update={updateChildComponent} />
                <HumanCreateIntervalWorkModal update={updateChildComponent} />
                <HumanCreateRateWorkModal update={updateChildComponent} />
            </div>
        );
    }
}

export default HumanCreate;


