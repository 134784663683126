/*jshint esversion: 6 */
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// import MenuList from "./MenuList";
import HumanList from './Components/human/HumanList';
import HumanDetail from './Components/human/HumanDetail';
import HumanCreate from './Components/human/HumanCreate';
// import HumanCreateOld from './Components/human/HumanCreateOld'
// import {render} from "react-dom";
import HumanMenu from "./Components/human/template/HumanMenu";
// import gs from "./human/Singleton"


// class App extends Component{
function App() {
    "use strict";

    return (
        <div>
            <BrowserRouter>
                <HumanMenu />
                <Switch>
                    <Route path="/" exact component={HumanList} />
                    <Route path="/human/:id/" exact component={HumanDetail} />
                    <Route path="/humanCreate/" exact component={HumanCreate} />
                {/*    <Route path="/humanCreateOld/" exact component={HumanCreateOld} />*/}
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
