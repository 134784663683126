/*jshint esversion: 6 */
import {Link,} from "react-router-dom";
import React from "react";
// import gs from "../../Singleton"

function HumanFilterSelect(){
          return (
 				// <!--------------------------FilterSelect--------------------------->
				  <div className="col-11 main__top d-flex m-auto">
					  <div className="main__filter d-flex align-items-center" id="filter_block1">
						  <p className="main__filter-left text-center">Filter 1</p>
						  <div className="main__filter-right d-flex justify-content-center align-items-center"
							   id="filter1">
							  <div className="main__filter-close" id="filter1"></div>
						  </div>
					  </div>
					  <div className="main__filter d-flex align-items-center">
						  <p className="main__filter-left text-center">Filter 2</p>
						  <div className="main__filter-right d-flex justify-content-center align-items-center">
							  <div className="main__filter-close"></div>
						  </div>
					  </div>
					  <div className="main__filter d-flex align-items-center">
						  <p className="main__filter-left text-center"><Link to="/humanCreateOld" >Filter 3</Link></p>
						  <div className="main__filter-right d-flex justify-content-center align-items-center">
							  <div className="main__filter-close"></div>
						  </div>
					  </div>
				  </div>
          )
}

export default HumanFilterSelect;
