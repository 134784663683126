/*jshint esversion: 6 */
import {Link,} from "react-router-dom";
import React, {useState} from "react";
// import gs from "../../Singleton"

function HumanMenu(){

	const [view, setView] = useState(false);

	function hideView(){
		setView(!view);
	}

	return (
	<div>
		{/*<!----------------------------Menu---------------------------->*/}
		<section className = "menu" id = "menu">
			<div className="container-fluid">
				<div className="row">
					<Link to ={{ pathname: "https://www.seven-scale.com", fromDashboard: false}}  target="_blank" className="menu__left col-4 col-md-2 d-flex">
						<img src="/static/img/logo.svg" alt="logo" className="menu__logo" />
					</Link>
					<form className="menu__center col-8 d-none d-lg-flex align-items-center justify-content-center">
						<i className="fas fa-times menu__center-close d-lg-none d-block"></i>
						<div className="menu__center-left d-flex align-items-center justify-content-center">
							<input type="search" className = "menu__search-input" placeholder="I'm looking for..." />
							<div className="menu__icon">
								<input type="submit" className = "menu__search-submit w-100" value="" />
								<img src="/static/img/search.svg" alt="search" className = "menu__icon-img w-100" />
							</div>
						</div>
						<Link className="menu__center-right d-flex align-items-center justify-content-center">
							<img src="/static/img/circle-plus.svg" alt="plus" className = "menu__icon" />
							<p className="menu__search">Filter</p>
						</Link>
					</form>
					<div className="menu__right col-2 d-none d-lg-flex">
						<Link className="menu__button d-flex justify-content-center align-items-center" to="/humanCreate">
							<i className="far fa-user-circle"></i>
							<p className="menu__button-text">
								Add Profile
							</p>
						</Link>
					</div>
					<Link className="menu__open-hamburger ml-auto d-flex d-lg-none justify-content-center align-items-center"
					onClick={hideView}>
						<i className="fas fa-bars"></i>
					</Link>
				</div>
			</div>
		</section>
		<section className = {view === true?"menu__open d-block":"menu__open d-none"} id = "menu__open">
			<div className="menu__open-content d-flex justify-content-between pb-5">
				<Link to={{ pathname: "https://seven-scale.com", fromDashboard: false}} target="_blank"
					  className = "menu__open-logo d-flex">
					<img src="/static/img/logo.svg" alt="logo" className="menu__logo" />
				</Link>
				<div onClick={hideView}>
					<i className="fas fa-times menu__close"></i>
				</div>
			</div>
			<div className="menu__open-content d-flex align-items-center flex-column justify-content-around">
				<Link className="menu__open-text" onClick={() => {setView(false)}} to="/">List</Link>
				<Link className="menu__open-text" onClick={() => {setView(false)}} id = "filter_link">Search and filter</Link>
				<Link className="menu__open-text" onClick={() => {setView(false)}} to="/humanCreate">Add profile</Link>
			</div>
		</section>
	</div>
	);
}

export default HumanMenu;
