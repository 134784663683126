/*jshint esversion: 6 */
const scheme = 'https://';
const host = 'human.seven-scale.com/';
const path_to_api = 'api/v0.1/';

const baseUrl = scheme + host + path_to_api;

export const url_api_human = baseUrl + 'human/';
export const url_api_gender = baseUrl + 'gender/';
export const url_api_level_language = baseUrl + 'levellanguage/';
export const url_api_city = baseUrl + 'city/';
export const url_api_language_programming = baseUrl + 'languageprogramming/';
export const url_api_framework_programming = baseUrl + 'frameworkprogramming/';
export const url_api_skill_programming = baseUrl + 'skillprogramming/';
// export const currentCustomerCartUrl = baseUrl + 'cart/current_customer_cart/'
// export const categoryUrl = baseUrl + `category/`
// export const productUrl = baseUrl + `product/`
// export const removeFromCartUrl = currentCustomerCartUrl + 'remove_from_cart/'
// export const addToCartUrl = currentCustomerCartUrl + 'add_to_cart/'
// export const changeQtyUrl = currentCustomerCartUrl + 'change_qty/'
// export const checkUserIsAuthenticatedUrl = baseUrl + 'check-user-is-authenticated/'
// export const obtainJwtTokenUrl = baseUrl + 'token
// export const currentCustomerCartUrl = baseUrl + 'cart/current_customer_cart/'
// export const categoryUrl = baseUrl + `category/`
// export const productUrl = baseUrl + `product/`
// export const removeFromCartUrl = currentCustomerCartUrl + 'remove_from_cart/'
// export const addToCartUrl = currentCustomerCartUrl + 'add_to_cart/'
// export const changeQtyUrl = currentCustomerCartUrl + 'change_qty/'
export const checkUserIsAuthenticatedUrl = baseUrl + 'check-user-is-authenticated/';
export const obtainJwtTokenUrl = baseUrl + 'token/';
