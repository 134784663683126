/*jshint esversion: 6 */
import {Link} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import gs from "../Singleton";
// const gs = new GlobalState(0);
import HumanPreloader from "./template/HumanPreloader";
import HumanErrorGet from "./template/HumanErrorGet";
import axios from "axios";
import {url_api_human} from "./ApiUrls";


function HumanDetail({match}){
    const [human, setHuman]=useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState(false);
    const id = match.params.id;
    const host = gs.getHost();


     useEffect( () => {
        axios({
            method: "GET",
            url: url_api_human+`${id}/`,
            responseType: 'json'
        })
        .then(response => {
            setHuman(response.data);
            setIsLoaded(true);
        })
        .catch(function (error) {
            setError(error);
            setIsLoaded(true);
        });
        setAlert(false);
        setIsLoaded(false);
    }, [id, alert]);

    // const levels = levle => {
    function levels(level) {
        if ( (level === 1) || (level === 0) ){
            return "Entry";
            }
        if (level === 2){
            return "Intermediate";
            }
        if (level === 3){
            return "Expert";
            }
    }

    if (error) {
        return ( <HumanErrorGet error={error} /> );
    } else if (!isLoaded) {
        return( <HumanPreloader /> );
    } else if (!human.name) {
      return( <HumanPreloader /> );
    } else {
    return (
        <div>
            {/*<!-------------------------------Account-------------------->*/}
            <section className="account" id="account">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-11 col-12 account__modal p-0">
                        <div className="account__header col-12">
                            <div
                                className="account__header-text col-md-9 col-11 m-auto d-flex justify-content-between p-0">
                                <h1 className="account__title">
                                    {human.name}
                                </h1>
                                <Link to="/" className="account__button">Close page</Link>
                            </div>
                        </div>
                        <div className="account__body">
                            <div
                                className="account__block d-flex justify-content-between align-items-baseline col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Human</p>
                                <p className="account__second-block col-6">{human.pk}</p>
                            </div>
                            <div className="account__block d-flex justify-content-between col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Specialist Level</p>
                                <p className="account__second-block col-6 ">{human.specialist_level}</p>
                            </div>
                            <div className="account__block d-flex justify-content-between col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Gender</p>
                                <p className="account__second-block col-6 ">{human.gender}</p>
                            </div>
                            <div className="account__block d-flex justify-content-between col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Domen</p>
                                <p className="account__second-block col-6 ">{human.city ? human.city.country ? human.city.country.domen:"":""}</p>
                            </div>
                            <div className="account__block d-flex justify-content-between col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">City</p>
                                <p className="account__second-block col-6 ">{human.city?human.city.title?human.city.title:"":""}</p>
                            </div>
                            <div className="account__block d-flex justify-content-between col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Country</p>
                                <p className="account__second-block col-6 ">{human.city ? human.city.country ? human.city.country.title:"":""}</p>
                            </div>
                            <div className="account__block d-flex justify-content-between col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">TimeZone</p>
                                <p className="account__second-block col-6 ">{
                                    human.city ?
                                        human.city.timezone ?
                                            human.city.timezone.hours > 0 ?
                                                "+ " + human.city.timezone.hours:
                                                "- " + human.city.timezone.hours:
                                            "":
                                        ""}</p>
                            </div>
                            <div
                                className="account__block d-flex justify-content-between align-items-baseline col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">CEFR</p>
                                <p className="account__second-block col-6">{human.level_english?human.level_english.CEFR?"Yes":"No":""}</p>
                            </div>
                            <div
                                className="account__block d-flex justify-content-between align-items-baseline col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Level</p>
                                <p className="account__second-block col-6">{human.level_english?human.level_english.title:""}</p>
                            </div>
                            <div
                                className="account__block d-flex justify-content-between align-items-baseline col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Language Programming</p>
                                <div className="account__second-block col-6">
                                    {human.language_programming.map(h => h.pk ?
                                        <p>{h.title} - {levels(h.level)}</p>
                                    :"")}
                                </div>
                            </div>
                            <div
                                className="account__block d-flex justify-content-between align-items-baseline col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Framework Programming</p>
                                <div className="account__second-block col-6">
                                    {human.framework_programming.map(h => h.pk ?
                                        <p>{h.title} ({h.language?h.language.title:""}) - {levels(h.level)}</p>
                                    :"")}
                                </div>
                            </div>
                            <div
                                className="account__block d-flex justify-content-between align-items-baseline col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Skill Programming</p>
                                <div className="account__second-block col-6">
                                    {human.skills_programming.map(h => h.pk ?
                                        <p>{h.title} - {levels(h.level)}</p>
                                    :"")}
                                </div>
                            </div>
                            <div
                                className="account__block d-flex justify-content-between align-items-baseline col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Interval Work</p>
                                <div className="account__second-block col-6">
                                    {human.interval_works.map(h => h.pk ?
                                        <p>{h.title} ( {h.timeFrom} - {h.timeTo} )</p>
                                    :"")}
                                </div>
                            </div>
                            <div
                                className="account__block d-flex justify-content-between align-items-baseline col-md-9 col-11 m-auto">
                                <p className="account__first-block col-6 ">Rate Work</p>
                                <div className="account__second-block col-6">
                                    {human.rate_works.map(h => h.pk ?
                                        <p>{h.title} - {h.price_dollar} $</p>
                                    :"")}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
  }
}

export default HumanDetail;
