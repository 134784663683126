/*jshint esversion: 6 */
import {Link,} from "react-router-dom";
import React from "react";
// import gs from "../../Singleton"

function HumanPreloader(){
          return (
          	<div>
				{/*<!----------------------------Menu---------------------------->*/}
                Загрузка...
                <center>
                    <div id="preloader" style={{position: 'absolute', top: '50%', left: '50%', margin: '-50px 0px 0px -50px'}}>
                        <img height="30%" src="/static/img/Hourglass.gif" alt=""/>
                    </div>
                </center>
          	</div>
          );
}

export default HumanPreloader;
